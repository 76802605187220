import https from "./https"
import store from "@/store"

const staff = {
  getStaffs (organization, params) {
    return https.get('admin/organizations/' + organization.id + '/staffs', { params })
  },
  getManagedStaffs (params) {
    return https.get('admin/staffs/managed', { params })
  },
  getOneStaff (staffId) {
    return https.get('admin/staffs/' + staffId)
  },
  updateStaff (staff) {
    const url = 'admin/staffs/' + staff.id

    return https.put(url, staff)
  },
  removeStaff (organization, staff) {
    const url = 'admin/organizations/' + organization.id + '/staffs/' + staff.id;
    return https.delete(url)
  },
  storeStaff (staff) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/staffs'

    return https.post(url, staff)
  },
  changePassword (staffId, params) {
    const url = 'admin/staffs/' + staffId + '/change-password'

    return https.put(url, params)
  },
  uploadStaff (formData) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/staffs/import`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  setBranches (staffId, params) {
    const url = 'admin/staffs/' + staffId + '/set-branches'

    return https.put(url, params)
  },
  getCustomers (staffId, params) {
    const organization = store.state.general.organization
    return https.get(
      `/admin/organizations/${organization.id}/staffs/${staffId}/customers`,
      { params }
    );
  },
  changeStaff(staffId, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/staffs/${staffId}/change-staff`,
      params
    );
  },
  unbindCustomers(staffId, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/staffs/${staffId}/unbind-customers`,
      params
    );
  },
  changeBoundType(staffId, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/staffs/${staffId}/change-bound-type`,
      params
    );
  },
  bindCustomer(staffId, params) {
    const organization = store.state.general.organization;
    return https.post(
      `/admin/organizations/${organization.id}/staffs/${staffId}/bind-customer`,
      params
    );
  },
  getManagedStaffOptions () {
    return https.get(`admin/staffs/managed-options`)
  },
  exportManagedStaffs(params) {
    return https.get(`admin/staffs/managed-export`, { params });
  },
  exportManagedStaffsWithBindingUrls(params) {
    return https.get(`admin/staffs/managed-export-with-binding-urls`, { params });
  },
  exportBindingQrcodes(params) {
    return https.get(`admin/staffs/managed-export-binding-qrcodes`, { params });
  },
  uploadAvatars(images, branchId) {
    const organization = store.state.general.organization;

    let data = new FormData();
    images.forEach((image, i) => {
      data.append(`images[${i}]`, image);
    });

    if (branchId) {
      return https.post(`/admin/organizations/${organization.id}/branches/${branchId}/staffs/import-avatars`, data);
    }

    return https.post(`/admin/organizations/${organization.id}/staffs/import-avatars`, data);
  },
  uploadPassword (formData, branchId) {
    const organization = store.state.general.organization
    const url = branchId
      ? `admin/organizations/${organization.id}/branches/${branchId}/staffs/import-passwords`
      : `admin/organizations/${organization.id}/staffs/import-passwords`;

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
}

export default staff
